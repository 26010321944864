import React, { useEffect, useState } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { getYMLUrl } from '../../api/api';
import yaml from 'js-yaml';

const SwaggerLoader = ({appId}) => {
  const [spec, setSpec] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchYAML = async () => {
      try {
        // Pass an appId for the api to pull from Azure
        if (appId === ""){
          throw new Error("Missing Application ID")
        }
        const yamlUrl = await getYMLUrl(appId);

        const response = await fetch(yamlUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch the YAML file');
        }

        const yamlText = await response.text();
        const spec = yaml.load(yamlText);

        setSpec(spec);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchYAML();
  }, [appId]);

  if (error) {
    return <div>Error: "Unable to load Swagger File"</div>;
  }

  return spec ? (
    <SwaggerUI spec={spec} />
  ) : (
    <div>Loading...</div>
  );
};

export default SwaggerLoader;