export const cdcFooterLinks = [
  ["U.S. Department of Health & Human Services", "https://www.hhs.gov/"],
  ["Accessibility", "https://www.cdc.gov/other/accessibility.html"],
  ["External Links", "https://www.cdc.gov/Other/disclaimer.html#exit-notification"],
  ["Privacy", "https://www.cdc.gov/other/privacy.html"],
  ["Policies", "https://www.cdc.gov/Other/policies.html"],
  ["No Fear Act", "https://www.cdc.gov/oeeowe/nofearact/"],
  ["FOIA", "https://www.cdc.gov/od/foia/"],
  ["Nondiscrimination", "https://www.cdc.gov/other/nondiscrimination.html"],
  ["OIG", "https://oig.hhs.gov/"],
  ["Vulnerability Disclosure", "https://www.hhs.gov/vulnerability-disclosure-policy/index.html"],
  ["USA.gov", "https://usa.gov/"]  
]

export const mobileAccordionMenuItems = [
  {
    id: 1,
    title: "Contact CDC",
    content: [
      {text: "Contact Us", href: "https://www.cdc.gov/cdc-info/forms/contact-us.html"},
      {text: "Call 800-232-4636", href: "tel:800-232-4636"}
    ]
  },
  {
    id: 2,
    title: "About CDC",
    content: [
      {text: "About CDC", href: "https://www.cdc.gov/about/"},
      {text: "Jobs", href: "https://jobs.cdc.gov/index.html"},
      {text: "Funding", href: "https://www.cdc.gov/budget/"}
    ]
  },
  {
    id: 3,
    title: "Policies",
    content: [
      {text: "Accessibility", href: "https://www.cdc.gov/other/accessibility.html"},
      {text: "External links", href: "https://www.cdc.gov/Other/disclaimer.html#exit-notification"},
      {text: "Privacy", href: "https://www.cdc.gov/other/privacy.html"},
      {text: "Policies", href: "https://www.cdc.gov/Other/policies.html"},
      {text: "No Fear Act", href: "https://www.cdc.gov/oeeowe/nofearact/"},
      {text: "FOIA", href: "https://www.cdc.gov/od/foia/"},
      {text: "Nondiscrimination", href: "https://www.cdc.gov/other/nondiscrimination.html"},
      {text: "OIG", href: "https://oig.hhs.gov/"},
      {text: "Vulnerability Disclosure Policy", href: "https://www.hhs.gov/vulnerability-disclosure-policy/index.html"}
    ]
  }
]