import React from "react";
import HelpAndSupportTemplate from "./HelpAndSupportTemplate";

const HelpAndSupport = () => {

  return (
    <HelpAndSupportTemplate>
      <section>
        <h1>Help & Support</h1>
        <p>Digital Gateway is designed as a one-stop shop that developers can use to access CDC APIs.</p>
        <p>Cannot find what you’re looking for? No problem&mdash;submit a support ticket to contact our Support Team and get the answers you need in no time at all.</p>
        <p>For more general questions, check out our FAQs to access the information you need to start using CDC Digital Gateway today.</p>

        <h2>Start Using Digital Gateway</h2>
        <div>
          <a className='usa-button' href='/help/become-provider'>Become an API Provider</a>
        </div>
      </section>

      <section className="grid-col-12">
        <div className="grid-row grid-gap flex-justify-center">
          <div className="display-none tablet:display-block grid-col-4"></div>
          <div className="grid-col-8">
            <h3 className="margin-top-5">Support Tickets</h3>
            <p>We’re here to help you make the most of our CDC-approved APIs. If you run into any issues or want to provide feedback, submit a support ticket. Be sure to provide detailed information so a member of our Support Team can address your concerns as thoroughly as possible.</p>
            <div>
              <a className='usa-button  usa-button--outline' href='/support-ticket/submit-support-ticket'>Submit Ticket</a>
            </div>

            <h3>FAQs</h3>
            <p>Find answers to commonly asked questions about the Digital Gateway and everything it contains.</p>
            <div>
              <a className='usa-button usa-button--outline' href='/help/faq'>Visit FAQs</a>
            </div>
          </div>
        </div>
      </section>

    </HelpAndSupportTemplate>

  )
}

export default HelpAndSupport;
