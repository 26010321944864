import React, { useState } from "react";
import SupportTicketForm from "../../components/SupportTicket/SupportTicketForm";
import SupportTicketConfirmation from "./SupportTicketConfirmation";
import HelpAndSupportTemplate from "../HelpAndSupport/HelpAndSupportTemplate";

const SupportTicket = () => {
  const breadcrumbs = [
    { name: "Submit a Support Ticket"},
  ];
  const [ticketNumber, setTicketNumber] = useState("");
  return (
    <>
      {ticketNumber === "" ? (
        <>
          <HelpAndSupportTemplate
            crumb={breadcrumbs}
            overrideClasses="margin-0"
            hideSidebar
          >
            <SupportTicketForm setTicketNumber={setTicketNumber} />
          </HelpAndSupportTemplate>
        </>
      ) : (
        <HelpAndSupportTemplate crumb={{ name: "Support Ticket Sent" }}>
          <SupportTicketConfirmation ticketNumber={ticketNumber} />
        </HelpAndSupportTemplate>
      )}
    </>
  );
};

export default SupportTicket;
