import { ROUTES } from "./routeConstants";

export const menuItems =
[
  {
    id: 1,
    name: "API Library",
    url: ROUTES["API Library"],
    subMenu: [
      {
        id: 11,
        name: "DEX Upload API",
        subMenu: [
          {
            id: 111,
            name: "API Overview",
            url: `/docs/dex-uploadapi/api-overview`
          },
          {
            id: 112,
            name: "Documentation",
            url: `/docs/dex-uploadapi/documentation`,
            subMenu: [
              {
                id: 1121,
                name: "Swagger Documentation",
                url: `/docs/dex-uploadapi/documentation/swagger`,
              },
            ],
          },
          {
            id: 114,
            name: "Getting Started",
            url: `/docs/dex-uploadapi/get-started`
          },
          {
            id: 115,
            name: "Change History",
            url: `/docs/dex-uploadapi/change-history`
          },
          {
            id: 116,
            name: "API Help & Support",
            url: `/docs/dex-uploadapi/help-and-support`
          }
        ]
      }
    ]
  }
]