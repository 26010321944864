import { ROUTES } from "../../helpers/menus/routeConstants"

export const primaryMenuItems = [
  {
    id: 1,
    name: "Home",
    url: "/"
  },
  {
    id: 2,
    name: "API Library",
    url: ROUTES["API Library"]
  },
  {
    id: 3,
    name: "About Us",
    url: ROUTES["About Us"]
  },
  {
    id: 4,
    name: "API Status",
    url: ROUTES["API Status"]
  },
  {
    id: 5,
    name: "Help & Support",
    url: ROUTES["Help & Support"],
    subMenu: [
      {
        id: 51,
        name: "Become an API Provider",
        url: ROUTES["Become an API Provider"]
      },
      {
        id: 52,
        name: "Submit a Support Ticket",
        url: ROUTES["Submit a Support Ticket"]
      },
      {
        id: 53,
        name: "FAQs",
        url: ROUTES["FAQs"]
      }
    ]
  }
]