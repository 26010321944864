import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icons } from "@us-gov-cdc/cdc-react-icons"

import { 
  faXTwitter,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faPinterestP,
  faSnapchatGhost
} from '@fortawesome/free-brands-svg-icons';
import { faRss } from '@fortawesome/free-solid-svg-icons';

import { mobileAccordionMenuItems } from "./cdcFooterLinks";

const AccordionItem = ({ id, title, links, isOpen, onToggle, children }) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onToggle();
    }
  };

  return (
    <div>
      <h4 className="usa-accordion__heading border-bottom border-base-lightest">
        <button
          id={`accordion-header-${id}`}
          data-testid={`accordion-header-${id}`}
          type="button"
          className="display-flex flex-align-center flex-justify padding-2 width-full text-left bg-gray-90 text-base-lightest"
          aria-expanded={isOpen}
          aria-controls={`accordion-panel-${id}`}
          onClick={onToggle}
          onKeyDown={handleKeyDown}
        >
          <span>{title}</span>
          {isOpen? <Icons.ChevronUp/> : <Icons.ChevronDown/>}
        </button>
      </h4>
      <div 
        id={`accordion-panel-${id}`}
        data-testid={`accordion-panel-${id}`}
        role="region"
        hidden={!isOpen}
        className="usa-accordion__content usa-prose bg-gray-90 text-base-lightest"
      >
        {!!links.length && <ul className="usa-list--unstyled">
          {links.map((link, index) => (
            <li key={index} className="padding-bottom-1">
              <a className="text-base-lightest text-no-underline" href={link.href}> {link.text}</a>
            </li>
          ))}
        </ul>}
        {children}
      </div>
    </div>
  )
}

const MobileFooter = ({classNames}) => {
  const [openIndex, setOpenIndex] = useState(null);

  const socialLinks = [
    [faFacebookF, "https://www.facebook.com/CDC", "facebook"],
    [faXTwitter, "https://twitter.com/CDCgov", "twitter/x"],
    [faInstagram, "https://www.instagram.com/CDCgov", "instagram"],
    [faLinkedinIn, "https://www.linkedin.com/company/centers-for-disease-control-and-prevention", "linked in"],
    [faYoutube, "https://www.youtube.com/cdc", "youtube"],
    [faPinterestP, "https://www.pinterest.com/cdcgov", "pinterest"],
    [faSnapchatGhost, "https://www.snapchat.com/add/cdcgov", "snapchat"],
    [faRss, "https://www.cdc.gov/rss", "rss"]
  ]

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <footer className={`${classNames} usa-footer`}>
      <nav aria-label='Footer navigation'>
        <div className='usa-accordion'>
          {mobileAccordionMenuItems.map((item, index) => (
            <AccordionItem
              key={item.id}
              id={item.id}
              title={item.title}
              links={item.content}
              isOpen={openIndex === index}
              onToggle={() => toggleAccordion(index)}
            />
          ))}
            <AccordionItem
              key="4"
              id="4"
              title="Connect With Us"
              links={[]}
              isOpen={openIndex === 4}
              onToggle={() => toggleAccordion(4)}
            >
              <ul className="usa-list--unstyled grid-row flex-wrap">
                {socialLinks.map((social, index) => {
                  return (
                    <li key={index} className="grid-col-3 margin-y-2">
                      <a 
                        aria-label={`${social[2]} opens in a new tab`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="socials-circle display-flex flex-align-center flex-justify-center bg-base margin-x-1 text-base-lightest border-base-lightest border-2px" 
                        href={social[1]}>
                        <FontAwesomeIcon icon={social[0]} />
                      </a>
                    </li>
                  )
                })}
              </ul>
            </AccordionItem>
        </div>
        <div>
          <ul className="usa-list--unstyled">
            <li className="display-inline-block padding-2">
              <a className="text-base-darkest text-no-underline" href="https://hhs.gov/">HHS.gov</a>
            </li>
            <li className="display-inline-block padding-2">
              <a className="text-base-darkest text-no-underline" href="https://usa.gov/">USA.gov</a>
            </li>
          </ul>
        </div>
      </nav>
    </footer>
  )
}

export default MobileFooter;