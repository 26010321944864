import React from "react";
import PrimaryNav from "../../components/PrimaryNav/PrimaryNav";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import results from "../../components/APILibrary/results.json";
import LibraryCard from "../../components/APILibrary/LibraryCard";
const APILibrary = () => {
  let breadcrumbs = [{ name: "Home", link: "/" }, { name: "API Library" }];
  
  const numberOfResults = results.length;
  return (
    <>
      <PrimaryNav />
      <Breadcrumbs crumbs={breadcrumbs} />
      <div className="tablet:padding-x-10 padding-y-3">
        <h1 className="page-title">API Library</h1>
        <p>
          Welcome to the API Library on the CDC Digital Gateway Developer
          Portal. Here, you can find a collection of CDC APIs designed to
          support your public health projects. Flip through our library to
          discover the tools and resources you need to access, integrate, and
          utilize vital health data. Start exploring today to see how our APIs
          can help you drive meaningful impact in your work.{" "}
        </p>
        <p>
          Our library will grow as we add new APIs to the CDC Digital Gateway.
          Check back often to stay up to date with all the latest additions.
        </p>
      </div>
      <div className="light-blue-background tablet:padding-x-10 padding-y-3">
        <h2>Explore Our API Library</h2>
        <p>
          Browse the API library below to discover the APIs and products you
          need to transport, validate, and observe CDC public health data.
        </p>


      <div className="library-results">
        Showing {numberOfResults} of {numberOfResults} APIs
      </div>
      {results.map((result, index) => (
        <LibraryCard key={index} api={result} />
      ))}

      </div>
    </>
  );
};

export default APILibrary;