import React from "react";
import { Link } from "react-router-dom"
import PrimaryNav from "../../components/PrimaryNav/PrimaryNav";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

const AboutUs = () => {
    const boxClasses = 
    'grid-col-12 tablet:grid-col-6 border-right border-base-lighter padding-4'
    const breadcrumbs = [
        {name: "Home", link: "/"},
        {name: "About Us"}
    ]
    return(
  <>
    <PrimaryNav />
    <main>
    <Breadcrumbs crumbs={breadcrumbs} />
    <div className="padding-x-10 margin-y-5">

    <h1 className="page-title">About Us</h1>

    <p>CDC’s Digital Gateway is a digital inventory of CDC application programming interfaces (APIs) that enables both external partners and internal programs to seamlessly integrate their data with CDC’s core systems.</p>

    <h2 className="page-title">What We Do</h2>

    <p>Digital Gateway catalogues CDC APIs and services under one roof to ensure they are standardized, secure, accessible, and socialized for public use.
       Whether you want to use a CDC-approved API or share an API you have built for CDC to use, Digital Gateway is your one-stop shop for exchanging safe and secure data with CDC. </p>

    <h2 className="page-title">Why We Do It</h2>

    <p>Our mission is to connect people at all levels of public health with the data they need faster to make decisions by providing a centralized hub, supported by real-time public health data.    </p>
    
    <p>Digital Gateway helps data and information flow seamlessly between different systems and organizations, we can create a more connected, efficient healthcare ecosystem together.</p>

    <h2 className="page-title">Our Ecosystem</h2>

    <p>CDC Digital Gateway is part of the wider data modernization efforts at CDC that include Enterprise Data Exchange (DEX) and CDC Public Health Data Strategy.</p>

    <p>DEX is a cloud-based, centralized data service created by the CDC. It is designed to securely ingest, validate, and transport large amounts of public health data to CDC programs, acting as the CDC’s front door in the data intake process.</p>

    <p>Here, DEX simplifies data exchange by standardizing points of entry via scalable, secure, and automated options for internal CDC partners and programs, as well as those from jurisdictional reporters like state and local governments, health systems, laboratories, and private entities.</p>
    <div className='grid-row light-blue-background'>
        <div className={boxClasses}>
          <h2>DEX Upload API</h2>
          <p className='text-left minh-10'>DEX Upload API
            This tool was developed to support CDC partners in their effort to share critical public health data with the CDC.
            Use this specially designed tool to launch reliable uploads, access real-time information, support scalable operations, and more.</p>
          <Link className="usa-button usa-button--outline" to="/docs/dex-uploadapi/api-overview" data-testid='tutorials-link'>Learn More</Link>
        </div>

        <div className={boxClasses}>
          <h2>Security</h2>
          <p className='text-left minh-10'>Access DEX Upload API with your Secure Access Information Services (SAMS) account to initiate our onboarding process.</p>
          <Link className="usa-button usa-button--outline" to="https://auth.cdc.gov/siteminderagent/forms/login.fcc" data-testid='standards-link'>Learn More</Link>
        </div>
      </div>
    </div>
    </main>
  </>
  )
}

export default AboutUs;
 