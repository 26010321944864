import React from "react";
import HelpAndSupportTemplate from "../HelpAndSupport/HelpAndSupportTemplate";

const BecomeAPIProvider = () => {
  let crumb = { name: "Become an API Provider", link: "/help/become-provider" }

  return (
    <HelpAndSupportTemplate crumb={crumb}>
      <section>
        <h1>Become an API Provider</h1>
        <p>If you’re a CDC Developer or Product Team and would like to add your API to the Digital Gateway, you’ll need to submit an API Registration Form. Select the button below to get started now. </p>
        <p>Want to learn more about onboarding your CDC API to the Digital Gateway? Visit <a href="https://docs.cdc.gov/">docs.cdc.gov</a> to access everything you need to know.</p>
        
        <h2 className="custom-h3">Submit a Tech Questionnaire</h2>
        <div>
          <a className='usa-button' href='https://servicedesk.cdc.gov/esc?id=sc_cat_item&table=sc_cat_item&sys_id=6c00be721be286d06aa6ece2604bcb33'>
            Get Started
          </a>
        </div>
      </section>

      <section className="grid-col-12">
        <div className="grid-row grid-gap">
          <div className="display-none tablet:display-block grid-col-4"></div>
          <div className="grid-col-8">
            <h2 className="custom-h3">Visit the Digital Gateway Docs Site</h2>
            <p>For more details around our platform, check out our documentation portal to learn about onboarding different APIs, formatting guidelines, training tutorials, security policies, and so much more.</p>
            <div>
              <a className='usa-button usa-button--outline' href='https://docs.cdc.gov/docs/dgw'>
                Go to Docs Site
              </a>
            </div>
          </div>
        </div>
      </section>

    </HelpAndSupportTemplate>

  )
}

export default BecomeAPIProvider;
