import axios from "axios";

export async function contactUsRequest(formData) {
  try {
    const data = `{ "sysparm_quantity": "1", "variables": ${JSON.stringify(
      formData
    )}}`;
    const apimURL = `https://${process.env.REACT_APP_GATEWAY_URL}/contact-us/order_now`;
    const response = await axios({
      url: apimURL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: data,
    });
    return response.data.result;
  } catch (error) {
    console.error("Error making the ServiceNow request:", error);
    throw error;
  }
}

export async function serviceNowRequest(formData) {
  try {
    const data = `{ "sysparm_quantity": "1", "variables": ${JSON.stringify(
      formData
    )}}`;
    const apimURL = `https://${process.env.REACT_APP_GATEWAY_URL}/service_now/order_now`;
    const response = await axios({
      url: apimURL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: data,
    });
    return response.data.result;
  } catch (error) {
    console.error("Error making the ServiceNow request:", error);
    throw error;
  }


}
export async function getYMLUrl(appId){
  try {
    const request = `https://${process.env.REACT_APP_GATEWAY_URL}/drs/v2.0/registrations/swagger/${appId}`;
    const response = await axios({
      url: request,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    return response.data.link
  } catch(error) {
    console.error("Unable to authorize access to Swagger Documentation.")
    throw error
  }
}

/* These calls are used to pull the public and provate APIs from the backend.

*/
// export async function getPublicAPIs(appId){
//   try {
//     const request = `https://ocio-dgw-dev-east-dgw-registration-service.azurewebsites.net/v2.0/registrations/public-apis`;
//     //    const request = `https://${process.env.REACT_APP_GATEWAY_URL}/v2.0/registrations/public-apis`;
//     const response = await axios({
//       url: request,
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//     })
//     return response.data;
//   } catch(error) {
//     console.error("Unable to authorize access to Public APIs.")
//     throw error
//   }
// }

// export async function getPrivateAPIs(jwt){
//   try {
// //    const request = `https://ocio-dgw-dev-east-dgw-registration-service.azurewebsites.net/v1.0/auth/token`;
//     const request = `https://ocio-dgw-dev-east-dgw-registration-service.azurewebsites.net/v2.0/registrations/private-apis`;
//     const response = await axios({
//       url: request,
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${jwt}`,
//       },
//     })
//     return response.data;
//   } catch(error) {
//     console.error("Unable to authorize access to Private APIs.")
//     throw error
//   }
// }
