import React, { useState } from "react";
import { faqContent } from "./questions";
import HelpAndSupportTemplate from "../HelpAndSupport/HelpAndSupportTemplate";

import "./Faq.css"

const Faq = () => {
  let crumb = { name: "FAQs", link: "/help/faq" }

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const renderFaq = (faq, i) => {
    const isOpen = openIndex === i;
    const content = (
      <div className="faq-box" key={i}>
        <h3 className="faq-question">
          <button onClick={() => handleToggle(i)} className="faq-toggle-button">
            <span>{isOpen ? '-' : '+'}</span>
          </button>
          {faq.question}
        </h3>
        {isOpen && (
          <div className="faq-answer">
            {faq.answer.map((ans) => {
              if (Array.isArray(ans)) {
                return (
                  <ul key={ans.id}>
                    {ans.map((listItem) => (
                      <li key={listItem.id}>{listItem}</li>
                    ))}
                  </ul>
                );
              }
              return <p key={ans.id}>{ans}</p>;
            })}
          </div>
        )}
      </div>
    );

    return content;
  };

  return (
    <HelpAndSupportTemplate crumb={crumb}>
      <section className="margin-bottom-10">
        <h1>Frequently Asked Questions</h1>
        <p>Check out our go-to resource for answers to common questions.<br />
        Browse to find helpful information, troubleshoot issues, and gain a deeper understanding of our products and services.</p>
      </section>

      <section className="grid-col-12">
        <div className="grid-row grid-gap flex-justify-center">
          <div className="display-none tablet:display-block grid-col-4"></div>
          <div className="grid-col-8">
            <h2>About Us</h2>
            {faqContent["About Us"].map((faq, i) => renderFaq(faq, i))}
            
            <h2 className="margin-top-5">Our APIs (Individual APIs and Products)</h2>
            {faqContent["Our APIs (Individual APIs and Products)"].map((faq, i) => renderFaq(faq, i + faqContent["About Us"].length))}
          </div>
        </div>
      </section>
    </HelpAndSupportTemplate>
  );
};

export default Faq;
