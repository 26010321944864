import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { 
  faXTwitter,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faPinterestP,
  faSnapchatGhost
 } from '@fortawesome/free-brands-svg-icons';
 import { faRss } from '@fortawesome/free-solid-svg-icons';


 import MobileFooter from "./MobileFooter";
 import { cdcFooterLinks } from "./cdcFooterLinks";

 import "./Footer.css"

const Footer = (props) => {
  const [socialLinks, setSocialLinks] = useState([])

  useEffect(() => {
    let socialLinks = [
      [faFacebookF, "https://www.facebook.com/CDC", "facebook"],
      [faXTwitter, "https://twitter.com/CDCgov", "twitter/x"],
      [faInstagram, "https://www.instagram.com/CDCgov", "instagram"],
      [faLinkedinIn, "https://www.linkedin.com/company/centers-for-disease-control-and-prevention", "linked in"],
      [faYoutube, "https://www.youtube.com/cdc", "youtube"],
      [faPinterestP, "https://www.pinterest.com/cdcgov", "pinterest"],
      [faSnapchatGhost, "https://www.snapchat.com/add/cdcgov", "snapchat"],
      [faRss, "https://www.cdc.gov/rss", "rss"]
    ]

    setSocialLinks(socialLinks)
  }, [])

  return (
    <div className="margin-top-5">
      <MobileFooter classNames='display-block desktop:display-none' />
      <footer className={`usa-footer display-none desktop:display-block text-base-lightest ${props.className ? props.className : ''}`}>
        <nav aria-label="Footer navigation" >
          <section className="grid-row grid-gap bg-gray-70 padding-y-2 tablet:padding-x-2 desktop:padding-x-10">
            <div className="grid-col-5 margin-y-auto">
              <ul className="usa-list usa-list--unstyled display-flex flex-row flex-justify" data-testid="contact">
                <li>
                  <a className="text-base-lightest text-no-underline" href="https://www.cdc.gov/about/">About CDC</a>
                </li>
                <li>
                  <a className="text-base-lightest text-no-underline" href="https://www.cdc.gov/cdc-info/index.html">Contact Us</a>
                </li>
                <li>
                800-232-4636
                </li>
              </ul>
            </div> 
            <div className="grid-col-7 margin-y-auto">
              <ul className="usa-list usa-list--unstyled display-flex flex-row flex-justify-end">
                {socialLinks.map((social, index) => {
                  return (
                    <li key={index} className="margin-x-1">
                      <a 
                        aria-label={social[2]}
                        className="socials-circle display-flex flex-align-center flex-justify-center bg-base margin-x-1 text-base-lightest border-base-lightest border-2px text-base-lightest"
                        href={social[1]}>
                        <FontAwesomeIcon icon={social[0]} />
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </section>
          <section className="padding-2 margin-x-auto desktop:padding-x-10">
            <ul className="usa-list usa-list--unstyled footer-links">
            {cdcFooterLinks.map((link, index) => {
              return (
                <li key={index}>
                  <Link className="text-base-darkest text-no-underline" to={link[1]}>{link[0]} </Link>
                </li>
              );
            })}
            </ul>
          </section>
        </nav>
      </footer>
    </div>
  )
}

export default Footer
