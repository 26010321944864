export const ROUTES = {
  "About Us": "/about-us",
  "API Library": "/apis",
  "API Status": "https://digitalgateway.statuspage.io/",
  "Help & Support": "/help",
  "Getting Started": "/getting-started",
  "Develop with our APIs": "/getting-started/dev-with-our-apis",
  "Become an API Provider": "/help/become-provider",
  "FAQs": "/help/faq",
  "Glossary": "/glossary",
  "Contact Us": "/contact",
  "Submit a Support Ticket": "/support-ticket/submit-support-ticket",
  "Request": "/products/access/request",
}
