import React from "react";
import PrimaryNav from "../../components/PrimaryNav/PrimaryNav";

function Page404() {
    return (
<>
<PrimaryNav />
<div className="padding-x-10 margin-y-25">

        <h1>Sorry, we couldn't find that page.</h1>
        <h3>The page you're looking for may have been removed or is tempoarily unavailable.</h3>
        <p>If you typed the URL before selecting send, double-check your spelling.</p>
        <p>Otherwise, visit our homepage for more helpful tools or contact us for additional assistance.</p>
        <a href="/" className="usa-button">Visit Homepage</a>
        <a href="/contact" className="usa-button">Contact DGW Support</a>
        <p>Error code: 404</p>
      </div>
      </>
    );
  }
  export default Page404;
  