import React from "react";

const LibraryCard = ({ api }) => {
  return (
  <a href={api.link || '#'} className="library-card-box">
    <div className="tablet:padding-x-2 padding-y-1 margin-y-5 library-card bg-white">
      <p className="title">{api.name}</p>
      <p>{api.description}</p>
      <p>
        <span className="text-bold">Date Available:</span> {api.availability}
      </p>
    </div></a>
  );
};

// used for dynamic API lists
// const LibraryCard = ({ api, description }) => {
//   const cardContent = (
//     <div className="tablet:padding-x-2 padding-y-1 margin-y-5 library-card bg-white">
//       <p className={api.link ? "title" : "title-nolink"}>
//         {api.apiName || "No API Name"}
//       </p>
//       <p>{description || "No Description Available"}</p>
//     </div>
//   );

//   return api.link ? (
//     <a href={api.link} className="library-card-box">
//       {cardContent}
//     </a>
//   ) : (
//     <div className="library-card-box">{cardContent}</div>
//   );
// };

export default LibraryCard;
