import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Icons } from "@us-gov-cdc/cdc-react-icons"

import SidebarNav from '../SidebarNav/SidebarNav';

import { primaryMenuItems } from './primaryNavMenuItems'
import { ROUTES } from '../../helpers/menus/routeConstants';

import './PrimaryNav.css'

const PrimaryNav = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isHelpMenuOpen, setIsHelpMenuOpen] = useState(false);
  const location = useLocation();

  const isHelpPageActive = location.pathname.includes('/help')

  const toggleMobileMenu = () => {
    closeHelpMenu()
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleHelpMenu = () => {
    setIsHelpMenuOpen(!isHelpMenuOpen);
  };

  const closeHelpMenu = () => {
    setIsHelpMenuOpen(false)
  }

  const primaryNavTabClasses = 'padding-1 desktop:padding-105 height-full nav-bg-dark-blue-hover display-inline-block text-base-lightest text-no-underline font-sans-3xs desktop:font-sans-lg'
  
  const hamburgerMenu = 
    <svg 
      className={`text-primary usa-icon usa-icon--size-4`}
      aria-label="Open the menu"
      xmlns="http://www.w3.org/2000/svg" 
      width="1em" 
      height="1em" 
      viewBox="0 0 24 24"  
      focusable="false"
      role="img">
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
    </svg>


  const closeButton =
    <svg 
      className={`text-primary usa-icon usa-icon--size-4`}
      aria-label="Close the menu"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      focusable="false"
      role="img">
      <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
    </svg>

  return (
    <nav className="primary-nav text-base-lightest tablet:padding-y-0 tablet:padding-x-10" role="navigation" aria-label="Primary Navigation">

      <div className='grid-row grid-gap'>
        <div className='grid-col-12 tablet:grid-col-6 tablet:display-flex flex-align-center'>

          <div className='primary-nav-logo grid-row flex-justify padding-x-3 padding-y-1 tablet:padding-x-0 tablet:padding-y-0'>

            <a className='display-inline-block flex-align-self-center' href='/'>
              <img src="/cdc-logo.svg" width="60vw" alt="CDC logo" />
            </a>

            <div 
              data-testid="mobile-menu-items" 
              className={`${isMobileMenuOpen ? 'bg-white' : '' } position-absolute top-0 right-0 z-top menu-toggle grid-col-9 flex-align-self-center text-right tablet:display-none`} 
              onClick={toggleMobileMenu}
            >
              <button aria-expanded={isMobileMenuOpen} aria-controls="menu-items" className="usa-button--unstyled margin-2" >
                {isMobileMenuOpen? closeButton : hamburgerMenu}
              </button>

              {isMobileMenuOpen && <SidebarNav
                menuItems={primaryMenuItems}
                overrideClasses={`${isMobileMenuOpen ? 'bg-white ' : 'display-none'} mobile-menu-items text-left bg-white margin-2 tablet:display-none`}
                id="mobile-menu-items"
              />
              }
            </div>
            <div className={`${isMobileMenuOpen ? '' : 'display-none'} bg-overlay position-fixed pin-all height-viewport width-full z-200 bg-black`}></div>
          </div>

          <div className='primary-nav-dg padding-x-3 tablet:padding-x-0'>
            <a href='/' className='display-inline-block text-base-lightest text-no-underline'>
              <p className='tablet:margin-left-1 font-sans-md desktop:font-sans-xl font-family-poppins'>
                DIGITAL GATEWAY
              </p>
            </a>
          </div>

        </div>

        {/* desktop menu items */}
        <div id="menu-items" data-testid="menu-items" className='display-none tablet:display-block grid-col-6 tablet:display-flex flex-align-end'>
          <ul className="usa-accordion display-flex flex-justify-end flex-column tablet:flex-row">
            <li key="apiLibrary" onMouseEnter={closeHelpMenu}>
              <NavLink 
                to={ROUTES["API Library"]} 
                className={props =>
                  props.isActive ? `${primaryNavTabClasses} nav-bg-dark-blue border-top border-white` : primaryNavTabClasses
                }
                >
                  API Library
              </NavLink>
            </li>

            <li key="about" className="margin-left-1" onMouseEnter={closeHelpMenu}>
              <NavLink 
                to={ROUTES["About Us"]} 
                className={props =>
                  props.isActive ? `${primaryNavTabClasses} nav-bg-dark-blue border-top border-white` : primaryNavTabClasses
                }
                >
                  About Us
              </NavLink>
            </li>

            <li key="status" className="margin-left-1" onMouseEnter={closeHelpMenu}>
              <NavLink 
                to={ROUTES["API Status"]} 
                target="_blank"
                rel="noopener noreferrer"
                aria-label="API Status page opens in a new tab"
                className={props =>
                  props.isActive ? `${primaryNavTabClasses} nav-bg-dark-blue border-top border-white` : primaryNavTabClasses
                }
                >
                  API Status
              </NavLink>
            </li>

            <li key="help" className='position-relative margin-left-1'>
              <button 
                className={`${isHelpMenuOpen || isHelpPageActive ? 'nav-bg-dark-blue border-top border-white' : ''} usa-button usa-button--unstyled padding-1 desktop:padding-105 height-full nav-bg-dark-blue-hover display-flex flex-align-center text-base-lightest text-no-underline font-sans-3xs desktop:font-sans-lg`}
                onClick={toggleHelpMenu}
                >
                  <span>Help & Support</span>
                  {isHelpMenuOpen ? 
                    <Icons.ChevronUp/>
                    :
                    <Icons.ChevronDown/>
                  }
              </button>

              <ul className={`${isHelpMenuOpen? '' : 'display-none'} usa-accordion usa-list--unstyled padding-105 nav-bg-dark-blue position-absolute z-top`}>
                <li className='font-sans-3xs' onClick={closeHelpMenu}>
                  <NavLink
                    to={ROUTES["Help & Support"]}
                    className='display-inline-block margin-bottom-1 text-base-lightest text-no-underline'
                  >
                    Help & Support Overview
                  </NavLink>
                  <NavLink 
                    to={ROUTES["Become an API Provider"]}
                    className='display-inline-block margin-bottom-1 text-base-lightest text-no-underline'
                  >
                    Become an API Provider
                  </NavLink>
                </li>
                <li className='font-sans-3xs' onClick={closeHelpMenu}>
                  <NavLink 
                    to={ROUTES["Submit a Support Ticket"]}
                    className='display-inline-block margin-bottom-1 text-base-lightest text-no-underline'
                  >
                    Submit a Support Ticket
                  </NavLink>
                </li>
                <li className='font-sans-3xs' onClick={closeHelpMenu}>
                  <NavLink 
                    to={ROUTES["FAQs"]}
                    className='display-inline-block margin-bottom-1 text-base-lightest text-no-underline'
                  >
                    FAQs
                  </NavLink>
                </li>
              </ul>
            </li>

            {/* Login will be used to retrieve dynamic Private API list in the API Library file
            <li key="status" onMouseEnter={closeHelpMenu}>
              <Link 
                to="https://dgwb2cdev.b2clogin.com/dgwb2cdev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_XQZ5_DGW_CDC&client_id=f5764da7-9747-44ce-93d9-fcfff8bd3a96&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3000&scope=openid&response_type=id_token&prompt=login"
                aria-label="API Status page opens in a new tab"
                className='padding-105 height-full nav-bg-dark-blue-hover display-inline-block text-base-lightest text-no-underline'>
                  Login
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default PrimaryNav;
