import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import SidebarNav from "../../components/SidebarNav/SidebarNav";
import PrimaryNav from "../../components/PrimaryNav/PrimaryNav";
import menuItems from "../../helpers/menus/sidebarMenu.json";


const HelpAndSupportTemplate = ({ children, crumb, hideSidebar, overrideClasses }) => {
    let breadcrumbs = [
      {name: "Home", link: "/"},
      {name: "Help & Support", link: "/help"}
    ]
  
    breadcrumbs = crumb ? breadcrumbs.concat(crumb) : breadcrumbs

    const childrenArray = React.Children.toArray(children)
    const firstChild = childrenArray[0];
    const blueBackgroundElements = childrenArray.slice(1)
  
    return (
      <div>
        <PrimaryNav />
        <Breadcrumbs crumbs={breadcrumbs} />
        <main>
          <div className={`${overrideClasses ? overrideClasses : "grid-row grid-gap padding-bottom-5 padding-x-2 tablet:padding-x-10"}`}>
            {!hideSidebar && <SidebarNav menuItems={menuItems} />}
            <div className={hideSidebar ? "" : "tablet:grid-col-8"}>
              { firstChild }
            </div>
          </div>
          { blueBackgroundElements.length > 0 && <div className="grid-row grid-gap padding-bottom-5 padding-x-2 tablet:padding-x-10 bg-light-blue">
            { blueBackgroundElements }
          </div>}
        </main>
      </div>
    );
  }
  export default HelpAndSupportTemplate
